import React from 'react'

class Video extends React.Component {
  render() {
    const data = this.props.data

    return (
      <div className="box-video">
        <div className="row">
          <div className="col-12">
            <div className="box-video__video">
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video width="100%" height="auto" controls>
                <source src={data.file.url} type="video/mp4" />
                Il browser non supporta il tag video
              </video>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Video
